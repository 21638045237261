import React, { Component } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

export default function HeaderSearch() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorP, setAnchorP] = React.useState(null);
  const [anchorQ, setAnchorQ] = React.useState(null);
  const [anchorC, setAnchorC] = React.useState(null);
  const [anchorS, setAnchorS] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickP = (event) => {
    setAnchorP(event.currentTarget);
  };
  const handleClickQ = (event) => {
    setAnchorQ(event.currentTarget);
  };
  const handleClickC = (event) => {
    setAnchorC(event.currentTarget);
  };
  const handleClickS = (event) => {
    setAnchorS(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleCloseP = () => {
    setAnchorP(null);
  };
  const handleCloseQ = () => {
    setAnchorQ(null);
  };
  const handleCloseC = () => {
    setAnchorC(null);
  };
  const handleCloseS = () => {
    setAnchorS(null);
  };

  const open1 = Boolean(anchorEl);

  const openP = Boolean(anchorP);
  const openQ = Boolean(anchorQ);
  const openC = Boolean(anchorC);
  const openS = Boolean(anchorS);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="full" className="border border-1 border-gray-300 border-x-0 border-t-0">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="">
            <style>{cssstyle}</style>
          <Grid item xs={2} md={1} className="text-gray-400 font-bold text-sm ">
              <div className="mt-1">Filter</div>
              
            </Grid>
            <Grid item xs={3} md={1} className="text-gray-400 hover:cursor-pointer">
              <ClickAwayListener
                onClickAway={() => {
                  handleCloseP();
                }}
              >
                <div>
                  <Button
                    aria-controls={openP ? "fade-menu" : undefined}
                    aria-expanded={openP ? "true" : undefined}
                    onClick={handleClickP}
                  >
                    <span className="text-gray-400 normal-case	">Price </span>
                    <ExpandMoreIcon className="text-gray-400" />
                  </Button>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorP}
                    open={openP}
                    onClose={handleCloseP}
                    TransitionComponent={Fade}
                    className="mobile:w-[80%] sm:w-[40%] xl:w-[30%]"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1} className="p-1">
                        <Grid item xs={12}>
                          <div className="p-1 text-sm font-bold">
                            Enter your EXW price
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div>Min</div>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-left">$ / Kg</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div>Max</div>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-left">$ / Kg</div>
                        </Grid>
                        
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Filter
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Clear
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Menu>
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={4} md={1} className="text-gray-400 hover:cursor-pointer">
              <ClickAwayListener
                onClickAway={() => {
                  handleCloseQ();
                }}
              >
                <div>
                  <Button
                    aria-controls={openQ ? "fade-menu" : undefined}
                    aria-expanded={openQ ? "true" : undefined}
                    onClick={handleClickQ}
                  >
                    <span className="text-gray-400 normal-case	">Quantity </span>
                    <ExpandMoreIcon className="text-gray-400" />
                  </Button>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorQ}
                    open={openQ}
                    onClose={handleCloseQ}
                    TransitionComponent={Fade}
                    className="mobile:w-[100%] md:w-[60%] lg:w-[35%]"

                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1} className="p-1">
                        <Grid item xs={12}>
                          <div className="p-1 text-sm font-bold">
                          Enter your desired quantity
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div>Min</div>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-left">MT</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Filter
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Clear
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Menu>
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={3} md={1} className="text-gray-400 hover:cursor-pointer">

              <ClickAwayListener
                onClickAway={() => {
                  handleCloseC();
                }}
              >
                <div>
                  <Button
                    aria-controls={openC ? "fade-menu" : undefined}
                    aria-expanded={openC ? "true" : undefined}
                    onClick={handleClickC}
                  >
                    <span className="text-gray-400 normal-case	">Chemical </span>
                    <ExpandMoreIcon className="text-gray-400" />
                  </Button>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorC}
                    open={openC}
                    onClose={handleCloseC}
                    TransitionComponent={Fade}
                    className="w-[48%]"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1} className="p-1">
                        <Grid item xs={12}>
                          <div className="p-1 text-sm font-bold">
                          Enter your chemical characteristics
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="text-sm">Free Fatty Acids</div>
                        </Grid>
                        <Grid item xs={5}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div className="text-left">%</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="text-sm">Peroxide value</div>
                        </Grid>
                        <Grid item xs={5}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-left">meq O2/Kg</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="text-sm">Spectrometry K232</div>
                        </Grid>
                        <Grid item xs={5}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          />
                        </Grid>
                        <Grid item xs={3}/>

                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Filter
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Clear
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Menu>
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={3} md={1} className="">
              <ClickAwayListener
                onClickAway={() => {
                  handleCloseS();
                }}
              >
                <div>
                  <Button
                    aria-controls={openS ? "fade-menu" : undefined}
                    aria-expanded={openS ? "true" : undefined}
                    onClick={handleClickS}
                  >
                    <span className="text-gray-400 normal-case	">Sensory </span>
                    <ExpandMoreIcon className="text-gray-400" />
                  </Button>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorS}
                    open={openS}
                    onClose={handleCloseS}
                    TransitionComponent={Fade}
                    className="w-[50%]"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1} className="p-1">
                        <Grid item xs={12}>
                          <div className="p-1 text-sm font-bold">
                            Enter your sensory characteristics
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div>Fruitiness</div>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <div>Bitterness</div>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div>Pungency</div>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            placeholder="0.00"
                            type="number"
                            className="border border-1 border-gray-400 w-4/5 rounded-md"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Filter
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text-center border border-1 border-black rounded-md italic font-ptSerif text-base">
                            Clear
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Menu>
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={4} md={5} />

            <Grid item xs={5} md={2} className="text-right hover:cursor-pointer">
              <ClickAwayListener
                onClickAway={() => {
                  handleClose1();
                }}
              >
                <div>
                  <Button
                    aria-controls={open1 ? "fade-menu" : undefined}
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  >
                    <span className="text-gray-400 normal-case	">Sort by </span>
                    <span className="text-black normal-case	"> Relevance</span>
                    <ExpandMoreIcon className="text-gray-400" />
                  </Button>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose1}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={(e) => {}}
                      className="p-2 border border-1 border-gray-200 border-t-0 
                          border-l-0 border-r-0"
                    >
                      Relevance
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {}}
                      className="p-2  border border-1 border-gray-200 border-t-0 border-l-0 border-r-0"
                    >
                      Newest
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {}}
                      className="p-2  border border-1 border-gray-200 border-t-0 border-l-0 border-r-0"
                    >
                      Price
                    </MenuItem>
                  </Menu>
                </div>
              </ClickAwayListener>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}

const cssstyle = `
.slick-next:before, .slick-prev:before {
    color: gray;
}
`;
