import Home from "./component/home"
import AboutUS from "./component/aboutUS"
import AllAboutEvoo from "./component/allAboutEvoo"
import Blog from "./component/blog"
import SellEvoo from "./component/sellEvoo"
import FiltreTags from "./component/filtreTags"
import TailorMadeService from "./component/tailorMadeService"
import AdminPage from "./component/admin/adminPage"
import UserList from "./component/admin/userList"
import { BrowserRouter as Router, Routes, Switch, Route } from 'react-router-dom';
import React, { useState } from 'react';
import Profile from "./component/user/profile"
import LotDetails from "./component/commun/lotDetail"
// import OrderNext from "./component/commun/orderNext"

// import store, { persistor } from "./store";

// import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Search from "./component/commun/search"
// import store from "./store/store"

function App() {
  const [token, setToken] = useState();

  return (
    <>
        {/*
        <PersistGate persistor={persistor} loading={null}> */}
 {/* <Provider store={store}> */}
      <Router >
        <Routes>
        {/* <Route exact path="/">
  {loggedIn ? <Redirect to="/dashboard" /> : <PublicHomePage />}
</Route> */}
          <Route path='/' exact element={<Home />} />
          <Route path='/admin'  element={<AdminPage />} />
          <Route path='/admin/userList'  element={<UserList />} />
          <Route path='/aboutUS'  element={<AboutUS />} />
          <Route path='/allAboutEvoo'  element={<AllAboutEvoo />} />
          <Route path='/blog'  element={<Blog />} />
          <Route path='/sellEvoo'  element={<SellEvoo />} />
          <Route path='/tailorMadeService'  element={<TailorMadeService />} />
          <Route path='/profil'  element={<Profile />} />
          <Route path='/lotDetails/:id'  element={<LotDetails />} />
          <Route path='/search/:id'  element={<Search />} />
          <Route path='/search'  element={<Search />} />
          <Route path='/filtreTags/:idd'  element={<FiltreTags />} />
          {/* <Route path="/next" component={OrderNext} />  */}
          {/* <Route path='/page'  element={<Page />} /> */}
          {/* <Route path='/page/:id' element={<Page/>} /> */}
        </Routes>
      </Router>
      {/* </PersistGate>
     */}
 {/* </Provider>  */}
    </>
  );
  
}
// Worker.unregister();
export default App;
