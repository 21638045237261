import React from "react";
import Head from "./head";
// admin home page
export default class AdminPage extends React.Component {
  render() {
    return (
      <div className=" flex flex-col h-screen justify-between">
        <Head />
       <div>
       </div>
      </div>
    );
  }
}
