import Header from "./header";
import Footer from "./footer";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardLot from "./cardLot";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function chooseColor(tags: any) {
  if (tags === "REGULAR QUALITY") return "border-regular";
  if (tags === "PREMIUM QUALITY") return "border-premium";
  if (tags === "GOOD QUALITY") return "border-good";
  if (tags === "ORGANIC") return "border-organic";
  if (tags === "ORGANIC PREMIUM") return "border-organicPremium";
}

export default function Search(callback) {
  const [lots, setLots] = React.useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("All results");
  const { id } = useParams();

  useEffect(() => {
    axios.get("http://127.0.0.1:8000/api/LotList/").then(
      (data) => {
        setLots(data.data);
        let term = id?id.toLowerCase():'';
        let result = [];   
        result = data.data.filter(
          (l) =>
            l.name.toLowerCase().includes(term) ||
            l.remark.toLowerCase().includes(term) 
        )
        setData(result);
        setTitle(id)
      }, 
      (error) => {
        console.log("Error Reading data " + error);
      }
    );   
  }, []);
    return (   
    <div className=" flex flex-col h-screen justify-between">       
      <div className=" mobile:h-[80%]">
        {/* entete */}
        <Header showHeaderSearch={true} />
      </div>

      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth="full"
          className="mobile:mt-[100%] mobile2:mt-[70%] sm:mt-[40%] md:mt-[20%] lg:mt-[15%]"
        >
         
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={2} />
              <Grid item xs={10}>
                <div className="mobile:text-3xl font-bold">{title}</div>
              </Grid>
              <Grid item xs={2}  />

              <Grid item xs={10}> 
                <div className="mobile:text-md ">{data.length} results</div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={1} md={1} lg={1} xl={2} />

              <Grid item xs={12} md={8} lg={10} xl={8}>
                <Box className="">
                 <Grid container spacing={2} className="mobile:text-sm ">
                    {" "}
                    {data.map((val, key) => {
                      const color = chooseColor(val.category.label);
                      return (
                        <Grid key={key} item sm={6} md={4} lg={3} xl={3}>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href = "/lotDetails/" + val.id;
                            }}
                          >
                            <CardLot
                              data={val}
                              color={color}
                              colorImage={val.color.label}
                            />
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={2} md={1} />
            </Grid>
          </Box>
        </Container>
      </React.Fragment>
      <div>
        <Footer />
      </div>
    </div>
  );
}
