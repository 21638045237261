export const cardExperienceData= [
    {
        id:'1',
        name: "BRIEF",
        description:'We strongly believe that the brief has a crucial importance in creating successful projects. It allows both client and our team to know and understand the expectations of the project, from simple operational requirements to the aspirations and vision of the client. It is also an opportunity to present 220, our services and our rates.',
        color:' text-blackO',
        why:'We strongly believe that the brief has a crucial importance in creating successful projects. It allows both client and our team to know and understand the expectations of the project, from simple operational requirements to the aspirations and vision of the client. It is also an opportunity to present 220, our services and our rates.',
        how:'The brief is a meeting between project main stakeholders. It is divided into two main moments : brief presentation of 220’s purpose, mission and case studies; followed by client’s aspiration and objectives.',
        what:'Generally, the meeting lasts 1h / 1h30 and is conducted through a zoom call. The minute of meeting (MoM) is sent in the form of a video recording to all participants.',
    },
    {
        id:'2',
        name: "MARKET INTELLIGENCE",
        description:'Consumption of olive oil outside its home territory has been rising steadily. Nevertheless, choosing the olive oil profile that matches specific market culinary habits or uses can be challenging. That’s why, providing decision-makers with a more “complete picture”  can save time & money.',
        color:' text-blueO',
        why:'Consumption of olive oil outside its home territory has been rising steadily. Nevertheless, choosing the olive oil profile that matches specific market culinary habits or uses can be challenging. That’s why, providing decision-makers with a more “complete picture”  can save time & money.',
        how:'Besides gathering data from external sources (news websites, company websites, social media, market research platforms) our market intelligence process is built on 20+ accumulated experience and based primarily on data from our network of producers, millers, exporters and clients.',
        what:'With client’s business objectives in mind, we provide a composite of sensory and chemicals characteristics.',
    },
    {
        id:'3',
        name: "COLLECTION OF SAMPLES",
        description:'Sample collection is carried out after the brief for 2 main reasons : to have a real-time view on what’s out in the market (oil inventories change rapidly) & to increase the likelihood of success (timing is an important factor).',
        color:' text-blueO',
        why:'Sample collection is carried out after the brief for 2 main reasons : to have a real-time view on what’s out in the market (oil inventories change rapidly) & to increase the likelihood of success (timing is an important factor).',
        how:'With the client aspirations and vision in mind, we identify the suppliers who are more likely to meet expectations and requirements. The samples are either collected by our coursiers or sent directly to us by suppliers.',
        what:'Samples collected in our Lab and ready for testing.',
    },
    {
        id:'4',
        name: "TASTING",
        description:'A first rigorous quality control is done to make a decision whether the samples are interesting or not. This allow us - and the client - gain in efficiency and effectiveness.',
        color:' text-blueO',
        why:'A first rigorous quality control is done to make a decision whether the samples are interesting or not. This allow us - and the client - gain in efficiency and effectiveness.',
        how:'The tasting is carried out by a “reduced panel”, a group of 2-3 specialists selected, trained and monitored, who apply internationally approved methods. Our sensory laboratory is COI certified.',
        what:'A choice of samples ready for the final selection and/or blending.',
    },
    {
        id:'5',
        name: "SAMPLES SELECTION & BLENDING",
        description:'The different subtle notes that occur in tasted samples allow us to select some oil pairing that can be blended together and create a carefully balanced taste profile. We blend, to provide consistent taste profiles from year to year, to emphasize specific characteristics or to come as close as possible to client demand.',
        color:' text-blueO',
        why:'The different subtle notes that occur in tasted samples allow us to select some oil pairing that can be blended together and create a carefully balanced taste profile. We blend, to provide consistent taste profiles from year to year, to emphasize specific characteristics or to come as close as possible to client demand.',
        how:'After the “reduced panel” delivers its verdict, the samples that are aligned with client demand or present desired notes are selected and analyzed more deeply. The blends are then prepared by Dr. Sonda & the team.',
        what:'A short list of samples & blends ready for sensory evaluation.',
    },
    {
        id:'6',
        name: "SENSORY ANALYSIS",
        description:'Using only chemical parameters is not enough. Through sensory evaluation, we are able to evaluate the positive and negative attributes of selected oil samples, classify them and ensure they match requirements and client expectations.',
        color:' text-blueO ',
        why:'Using only chemical parameters is not enough. Through sensory evaluation, we are able to evaluate the positive and negative attributes of selected oil samples, classify them and ensure they match requirements and client expectations.',
        how:'The analysis is carried out by our “test panel”, a recognized tasting group of 8-12 specialists selected, trained and monitored, who apply internationally approved methods. Our sensory laboratory is COI certified.',
        what:'Sensory analysis (5 samples at most). Fact sheets that include : oil sample profil, characteristics, positive and negative attributes, oil origin, harvest time, used equipment, …). Samples sent by DHL/UPS.',
    },
    {
        id:'7',
        name: "CONTRACTING ASSISTANCE",
        description:'We believe that the most important benefit of having a third party in this phase is that it creates less stress on both sides. ',
        color:' text-yellowO',
        why:'We believe that the most important benefit of having a third party in this phase is that it creates less stress on both sides. ',
        how:'Once the oil is selected and before the order is placed, we assist with negotiation of the price offer, the terms and conditions of the sale and contract drafting with our lawyers.',
        what:'A detailed contract or sale agreement that mentions the terms and conditions as well as the rights and obligations of the parties.',
    },
    {
        id:'8',
        name: "SAMPLING & TANK SEALING",
        description:'Being the “eye of the buyer” and ensuring that the sample collected is homogeneous, helps avoid confusion and uncertainty upon receipt of the goods.',
        color:' text-redO ',
        why:'Being the “eye of the buyer” and ensuring that the sample collected is homogeneous, helps avoid confusion and uncertainty upon receipt of the goods.',
        how:'Our team drives to the producer, takes the samples and seals the tanks (with ordered product) and then sends the samples to a third party laboratory for chemical analysis.',
        what:'Samples sent to the laboratory. Documentation & proof sent by email to the buyer.',
    },
    {
        id:'9',
        name: "LOGISTICS SUPPORT & FOLLOW UP",
        description:'We believe that taking out the burden of logistics from both buyers and suppliers, may be key to a strengthened long-term relationship. Logistics has proven to be of immense importance and the cost of neglect can be very high. That said, olive oil trade needs to be a headache-free operation. ',
        color:' text-redO',
        why:'We believe that taking out the burden of logistics from both buyers and suppliers, may be key to a strengthened long-term relationship. Logistics has proven to be of immense importance and the cost of neglect can be very high. That said, olive oil trade needs to be a headache-free operation. ',
        how:'We partnered with Marsaship - North Africain leader in olive oil bulk transport. Our joint teams will assist in all logistics steps, from ground transport, sea freight, accuracy of trade documents to local authorities facing and beyond.',
        what:'E2E support, from producers warehouse to your delivery point. Daily monitoring of shipment progress. Urgent Intervention in case of deadlock or problem. Privilège access to our 3PL partner Marsaship.',
    },
];
